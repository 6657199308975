import React, { useState, useEffect, useMemo, useContext } from 'react'
import './Login.css';
// import logo from '../../logo.svg';
import Mas_Clinica_Latam from '../../assets/Mas_Clinica_Latam.png';
import Connection from "../../connection";
import { BrowserRouter, Routes, Route, useNavigate, Link } from "react-router-dom"
import PulseLoader from "react-spinners/PulseLoader";
import { GlobalInfo } from "../../App"

function Login(props) {
  const { loginenter } = useContext(GlobalInfo)

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigat = useNavigate()

  const Login = async (event) => {
    event.preventDefault();

    if (!email) {
      alert('Please enter your email.');
    }
    else if (!password) {
      alert('Please enter your password.');
    }
    else {
      setIsLoading(true);

      try {
        let result = await fetch(Connection + "loginadmin", {
          method: 'post',
          body: JSON.stringify({ email, password }),
          headers: {
            "Content-Type": 'application/json'
          }
        })
        result = await result.json()
        const respons4 = result.response;
        console.log("result123", respons4)

        if (respons4 === 'fail') {
          alert('Something went wrong')
        }
        else {
          const Token = result.token
          localStorage.setItem("JWT_TOKEN", (Token))
          localStorage.setItem("user", JSON.stringify(respons4))
          loginenter()
          //  window.location.href = '/'
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Login error:', error);
        alert('An error occurred while logging in. Please try again later.');
      }
    }
  }


  return (
    <div class="main_Login_div">
      <div className='both_handle'>
        <img src={Mas_Clinica_Latam}></img>

        <div class="box">
          <h3>Welcome Back</h3>
          <h5>Enter your credentials to access your account.</h5>

          <form action="">
            <div class="input_box">
              <input type="text" name="Email" id="name" autocomplete="off" placeholder="Email" required onChange={(e) => setEmail(e.target.value)} />
              {/* <label for="Username">Email</label> */}
            </div>

            <div class="input_box">
              <input type="password" name="password" id="name" placeholder="Password" autocomplete="off" required onChange={(e) => setPassword(e.target.value)} />
              {/* <label for="Password">Password</label> */}
            </div>

            {isLoading ?
              <button id='delete_loader'>
                <PulseLoader
                  color={"white"}
                  loading={isLoading}
                  //  size={5}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </button>
              :
              <Link className="link" to={"/"} onClick={Login} value="Login" >Log In</Link>
            }

          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;