import React, { useState, useEffect } from 'react'
import './All_Promo.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Link, parsePath, useParams } from "react-router-dom"
import All_Promo_Paginations from './All_Promo_Paginations';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";

export default function All_Promo() {

    const [funds, setFunds] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(10);

    const lastPostIndex = currentPage * postsPerPage
    const firstPostIndex = lastPostIndex - postsPerPage
    const currentPosts = funds.slice(firstPostIndex, lastPostIndex)

    const [modalDelete, setModalDelete] = useState(false);

    const [id, setId] = React.useState("");
    const [balance, setBalance] = React.useState("");
    const [code, setCode] = React.useState("");

    const [patLoading, setPatLoading] = useState(false);

    const params = useParams();

    useEffect(() => {
        get_alpromo()
    }, [])


    const get_alpromo = async () => {
        try {
            let result = await fetch(Connection + "get_allpromo", {
                headers: {
                    'Authorization': localStorage.getItem("JWT_TOKEN")
                }
            })
            result = await result.json()
            const respons4 = result.response;

            if (respons4 === 'Token verification failed') {
                console.log(respons4);
            }
            else if (respons4 === 'fail') {
                console.log(respons4);
            }
            else {
                setFunds(respons4)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }


    const open_deletepromo_modal = (id, code) => {
        setModalDelete(true)

        setId(id)
        setCode(code)
    }

    const Delete_promocode = async (event) => {
        event.preventDefault();

        try {
            setPatLoading(true);

            let result = await fetch(Connection + `delete_promo_code/${id}`, {
                headers: {
                    'Authorization': localStorage.getItem("JWT_TOKEN")
                }
            })
            result = await result.json()
            const respons4 = result.response;
            setPatLoading(false);

            if (respons4 === 'Token verification failed') {
                console.log(respons4);
                alert('Something went wrong')
            }
            else if (respons4 === 'fail') {
                console.log(respons4);
                alert('Something went wrong')
            }
            else {
                setModalDelete(false)
                get_alpromo()
            }
        } catch (error) {
            console.error('Error:', error);
            setPatLoading(false);
            alert('Something went wrong')
        }
    }

    return (
        <div className='Add_funds_main_table'>

            <div className='respons_Add_funds_card'>
                <div className='head_card'>
                    <h2>All Promo</h2>
                </div>
                <hr></hr>

                <div className='Add_funds_table'>
                    <table className='Add_refunds_table_tag'>
                        <tr>
                            <th>#</th>
                            <th>Code</th>
                            <th>Percentage</th>
                            <th>Allow</th>
                            <th>Action</th>
                        </tr>


                        {currentPosts.map((row) => (
                            <tr>
                                <td>{row.id}</td>
                                <td>{row.code}</td>
                                <td>{row.percentage}</td>
                                <td>{row.allow}</td>
                                <td>
                                    <span className='transfer_btn'>
                                        <button onClick={() => open_deletepromo_modal(row.id, row.code)}>Delete Promo</button>
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>

            <All_Promo_Paginations totalPosts={funds.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage} />

            <Modal size='lg' isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
                <ModalHeader toggle={() => setModalDelete(!modalDelete)}>
                    DELETE PROMO
                </ModalHeader>

                <ModalBody>
                    <div className='delete_modal'>
                        <h4>Are you sure you want to delete this promo?</h4>
                        {patLoading ?
                            <button id='delete_loader'>
                                <PulseLoader
                                    color={"white"}
                                    loading={patLoading}
                                    //  size={5}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            <button onClick={Delete_promocode}>Delete</button>
                        }
                    </div>
                </ModalBody>
            </Modal>

        </div>
    );
}