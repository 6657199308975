import React, { useState, useEffect } from 'react'
import './Fucture_update.css';
import Connection from "../../connection";
import PulseLoader from "react-spinners/PulseLoader";
import { BrowserRouter, Routes, Route, useNavigate, Link } from "react-router-dom"
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';

export default function Fucture_update() {
    const navigat = useNavigate()

    const [error, setError] = useState(false);
    const [add_update, setAdd_update] = useState("");
    const [spanish_update, setSpanish_update] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [modalsuccess, setModalsuccess] = useState(false);

    const [getupdate, setGetupdate] = useState('');

    useEffect(() => {
        getUpdate()
    }, [])

    const getUpdate = async () => {
        try {
            const id = 1
            let result = await fetch(Connection + `getupdate/${id}`, {
                headers: {
                    'Authorization': localStorage.getItem("JWT_TOKEN")
                }
            })
            result = await result.json()
            const respons4 = result.response;

            if (respons4 === 'Token verification failed') {
                console.log(respons4);
            }
            else if (respons4 === 'fail') {
                console.log(respons4);
            }
            else {
                const add_update = respons4[0].add_update
                const spanish_update = respons4[0].spanish_update
                setGetupdate(respons4)
                setAdd_update(add_update)
                setSpanish_update(spanish_update)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }


    const Adupdate = async (event) => {
        event.preventDefault();

        try {
            if (!add_update || !spanish_update) {
                setError(true)
                alert('Please Enter both language Fucture Update.');
                return false
            }
            setIsLoading(true);

            console.log(add_update, spanish_update)

            let result = await fetch(Connection + "add_update", {
                method: 'post',
                body: JSON.stringify({ add_update, spanish_update }),
                headers: {
                    'Authorization': localStorage.getItem("JWT_TOKEN"),
                    "Content-Type": 'application/json'
                }
            })
            result = await result.json()
            const respons4 = result.response;
            setIsLoading(false);

            if (respons4 === 'Token verification failed') {
                console.log(respons4);
                alert('Something went wrong')
            }
            else if (respons4 === 'fail') {
                console.log(respons4);
                alert('Something went wrong')
            }
            else {
                setModalsuccess(true)
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
            alert('Something went wrong')
        }
    }


    const Exitupdate = async (event) => {
        event.preventDefault();

        try {
            setIsLoading(true);

            console.log(add_update, spanish_update)
            const id = 1

            let result = await fetch(Connection + `Exit_update/${id}`, {
                method: 'post',
                body: JSON.stringify({ add_update, spanish_update }),
                headers: {
                    'Authorization': localStorage.getItem("JWT_TOKEN"),
                    "Content-Type": 'application/json'
                }
            })
            result = await result.json()
            const respons4 = result.response;
            setIsLoading(false);

            if (respons4 === 'Token verification failed') {
                console.log(respons4);
                alert('Something went wrong')
            }
            else if (respons4 === 'fail') {
                console.log(respons4);
                alert('Something went wrong')
            }
            else {
                getUpdate()
                setModalsuccess(true)
                //   navigate('/')
            }
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
            alert('Something went wrong')
        }
    }

    const Succeess = async (event) => {
        setModalsuccess(false)
        navigat('/')
    }


    return (
        <div className='table_review'>

            <div className='responsive_review'>
                <div className='head_card'>
                    <h2>Add Fucture Update</h2>
                </div>
                <hr></hr>


                <div className='Update_Card'>

                    <div className="update_input">
                        <div className='update_width_subject'>
                            <div className='subject-name'>
                                <label>English Update</label>
                            </div>
                            <textarea className="message" onChange={(e) => setAdd_update(e.target.value)} value={add_update} placeholder="Leave a message here" id="update_inpu" ></textarea>
                        </div>


                        <div className='update_width_subject'>
                            <div className='subject-name'>
                                <label>Spanish Update</label>
                            </div>
                            <textarea className="message" onChange={(e) => setSpanish_update(e.target.value)} value={spanish_update} placeholder="Leave a message here" id="update_inpu" ></textarea>
                        </div>
                    </div>

                    <div class="Updatebuttun">
                        {isLoading ?
                            <button class="submit" id='loader' type="reset">
                                <PulseLoader
                                    color={"white"}
                                    loading={isLoading}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </button>
                            :
                            getupdate == null ?
                                (<button onClick={Adupdate} class="submit" id='submite' type="submit">Add Update</button>)
                                :
                                (<button onClick={Exitupdate} class="submit" id='submite' type="submit">Update</button>)
                        }
                    </div>
                </div>
            </div>


            <Modal size='lg' isOpen={modalsuccess} toggle={() => setModalsuccess(!modalsuccess)}>
                <ModalHeader toggle={() => setModalsuccess(!modalsuccess)}>
                    Successfully
                </ModalHeader>

                <ModalBody>
                    <div className='succeess_modal'>
                        <h4>You have successfully Add Fucture Update</h4>
                        <button onClick={Succeess}>Done</button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}